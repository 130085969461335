<template>
  <div>
    <b-card>
      <form-wizard
        ref="registerWizard"
        step-size="xs"
        :hide-buttons="true"
        color="#006435"
        title=""
        subtitle=""
      >
        <tab-content title="Informations Personnelles">
          <b-form>
            <b-row>
              <b-col sm="6">
                <b-form-group label="Nom">
                  <b-form-input
                    v-model.trim="$v.user.lastname.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.lastname.$error
                    }"
                  />
                  <div
                    v-if="$v.user.lastname.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.lastname.required">Nom requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Prénom (s)">
                  <b-form-input
                    v-model.trim="$v.user.firstname.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.firstname.$error
                    }"
                  />
                  <div
                    v-if="$v.user.firstname.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.firstname.required">Prénom (s) requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Email">
                  <b-form-input
                    v-model.trim="$v.user.email.$model"
                    :class="{
                      'is-invalid': $v.user.email.$error
                    }"
                    :formatter="lower"
                    @blur="checkEmail"
                  />
                  <div
                    v-if="$v.user.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.email.required">Email requis.</span>
                    <span v-if="!$v.user.email.email">Entrer un email valide.</span>
                  </div>
                  <div
                    v-if="emailExist"
                  >
                    <small class="sm text-danger">Email déjà utilisé.</small>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Téléphone">
                  <vue-phone-number-input
                    v-model.trim.trim="phone"
                    name="register-phone"
                    size="sm"
                    valid-color="#006435"
                    :dark="dark"
                    dark-color="transparent"
                    error-color="red"
                    :translations="translate"
                    color="#006435"
                    :fetch-country="true"
                    :only-countries="['BJ']"
                    :border-radius="4"
                    :required="true"
                    @update="update($event)"
                    @phone-number-blur="checkPhone($event)"
                  />
                  <div
                    v-if="phoneExist"
                  >
                    <small class="sm text-danger">Numéro de téléphone déjà utilisé.</small>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Adresse">
                  <b-form-input
                    v-model.trim="$v.user.address.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.address.$error
                    }"
                  />
                  <div
                    v-if="$v.user.address.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.address.required">Adresse requise.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Profession">
                  <b-form-input
                    v-model.trim="$v.user.profession.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.profession.$error
                    }"
                  />
                  <div
                    v-if="$v.user.profession.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.profession.required">Profession requise.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="float-right"
                  @click="stepOne"
                >
                  Suivant
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Détails du compte">
          <b-form>
            <b-row>
              <b-col sm="6">
                <b-form-group label="Nom d'utilisateur">
                  <b-form-input
                    v-model.trim="$v.user.username.$model"
                    :class="{
                      'is-invalid': $v.user.username.$error
                    }"
                    @blur="checkUsername"
                  />
                  <div
                    v-if="$v.user.username.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.username.required">Nom d'utilisateur requis</span>
                    <span v-if="!$v.user.username.minValue">Le nom d'utilisateur doit avoir au moins 6 caractères.
                    </span>
                    <span v-if="!$v.user.username.alphaNum">Le nom d'utilisateur peut contenir des chiffres et des
                      lettres.
                    </span>
                  </div>
                  <div
                    v-if="userExist"
                  >
                    <small class="sm text-danger">ELe nom d'utilisateur existe déjà utilisé.</small>
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="Droits utilisateurs">
                  <v-select
                    v-model="$v.user.roles.$model"
                    :options="userRoles"
                    multiple
                    :class="{
                      'is-invalid': $v.user.roles.$error
                    }"
                  />
                  <div
                    v-if="$v.user.roles.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.roles.required">Nom d'utilisateur requis</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Matricule">
                  <b-form-input
                    v-model.trim="$v.user.matricule.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.matricule.$error
                    }"
                  />
                  <div
                    v-if="$v.user.matricule.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.matricule.required">Le numéro matricule est requis</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Mot de passe">
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'is-invalid': $v.user.password.$error }"
                  >
                    <b-form-input
                      v-model="$v.user.password.$model"
                      :class="{
                        'is-invalid': $v.user.password.$error
                      }"
                      :type="passwordFieldType"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="KeyIcon"
                        @click="generatePassword"
                      />
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="CopyIcon"
                        @click="copyPassword"
                      />
                    </b-input-group-append>
                    <div
                      v-if="$v.user.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.password.required">Mot de passe requis</span>
                      <span v-if="!$v.user.password.password">Le mot de passe doit contenir au moins 8 caractères y compris une
                        majuscule et une lettre minuscule, un nombre et un caractère spécial.</span>
                    </div>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Confirmation">
                  <b-form-input
                    v-model="$v.user.confirmPassword.$model"
                    :class="{
                      'is-invalid': $v.user.confirmPassword.$error
                    }"
                    type="password"
                  />
                  <div
                    v-if="$v.user.confirmPassword.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.confirmPassword.required">Mot de passe requis</span>
                    <span v-if="!$v.user.confirmPassword.sameAs">Mots de passe non identiques</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="float-left"
                  :disabled="process"
                  @click="$refs.registerWizard.prevTab()"
                >
                  Précédent
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="float-right"
                  :disabled="process"
                  @click="finalStep"
                >
                  <b-spinner
                    v-if="process"
                    small
                  />
                  <span v-show="!process">Enrégister</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
      </form-wizard>
    </b-card>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  required, email, helpers, sameAs, minLength, alphaNum,
} from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    // BSV
    FormWizard,
    TabContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      phone: null,
      emailExist: null,
      phoneExist: null,
      userExist: null,
      isValid: false,
      userRoles: [{ label: 'Prévision Navire', value: 'ROLE_PREVISION_NAVIRE' }, { label: 'Actualités et Offres', value: 'ROLE_DIR_COM' }, { label: 'Cotation', value: 'ROLE_OPERATION' }, { label: 'Administrateur', value: 'ROLE_ADMIN' }, { label: 'Super Admin', value: 'ROLE_SUPER_ADMIN' }],
      user: {
        username: null,
        phoneNumber: null,
        email: null,
        roles: [],
        password: null,
        confirmPassword: null,
        lastname: null,
        firstname: null,
        address: null,
        matricule: null,
        profession: null,
        profileImage: '',
        callBackUrl: `${window.location.hostname}/confirm-account`,
      },
      translate: {
        countrySelectorLabel: 'Code pays',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Numéro de téléphone',
        example: 'Exemple :',
      },
    }
  },
  validations: {
    user: {
      username: {
        required,
        minLength: minLength(6),
        alphaNum,
      },
      phoneNumber: {
        required,
      },
      email: {
        required,
        email,
      },
      roles: {
        required,
      },
      password: {
        password: helpers.regex(
          'password',
          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        ),
      },
      confirmPassword: {
        // eslint-disable-next-line func-names
        sameAs: sameAs(function () {
          return this.user.password
        }),
      },
      lastname: {
        required,
      },
      firstname: {
        required,
      },
      address: {
        required,
      },
      matricule: {
        required,
      },
      profession: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'registerSuccess',
      process: 'registerProcess',
      error: 'registerError',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    dark() {
      if (window.localStorage.getItem('vuexy-skin') === 'dark') return true
      return false
    },
  },
  watch: {
    success(val) {
      if (val) {
        switch (val.valid) {
          case 0:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enrégistrement',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            window.location.reload()
            break
          default:
            this.reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enrégistrement',
                text: val.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    error(val) {
      if (val.code) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enrégistrement',
            text: `Une erreur est survenue lors de l'enregistrement. Code ${val.code}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['registerUser']),
    generatePassword() {
      const chars = "0123456789abcdefghijklmnopqrstuvwxyz!#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]ABCDEFGHIJKLMNOPQRSTUVWXYZ"
      let password = ''

      const lengthRegex = /^.*(?=.{8,}).*$/
      const upper = /^(?=.*?[A-Z])/
      const lower = /^(?=.*?[a-z])/
      const special = /^(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/
      const number = /^(?=.*?[0-9])/

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= 8; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
      }

      const validationRules = {
        size: lengthRegex.test(password),
        upper: upper.test(password),
        lower: lower.test(password),
        special: special.test(password),
        number: number.test(password),
      }

      if (
        validationRules.size
      && validationRules.upper
      && validationRules.lower
      && validationRules.special
      && validationRules.number
      ) {
        this.user.password = password
        this.user.confirmPassword = password
      } else {
        this.generatePassword()
      }
    },
    copyPassword() {
      navigator.clipboard.writeText(this.user.password)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Mot de passe',
          text: 'Mot de passe copié',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    update(event) {
      this.isValid = event.isValid
      if (event.isValid) {
        this.user.phoneNumber = event.formattedNumber
      }
    },
    formatter(value) {
      return value.toUpperCase()
    },
    lower(value) {
      return value.toLowerCase()
    },
    async checkEmail() {
      this.emailExist = null
      this.$v.user.email.$touch()
      if (this.$v.user.email.$error) return
      this.emailExist = await this.$http({
        method: 'post',
        url: `/api/oauth/check-email-exist?email=${this.user.email}`,
      })
        .then(res => {
          if (res.data.valid === 1) {
            return true
          }
          return false
        })
        .catch(() => 0)
    },
    async checkPhone() {
      this.phoneExist = null
      if (!this.isValid) return
      this.$v.user.phoneNumber.$touch()
      if (this.$v.user.phoneNumber.$error) return
      this.phoneExist = await this.$http({
        method: 'post',
        url: `/api/oauth/check-phone-exist?phoneNumber=${this.user.phoneNumber}`,
      })
        .then(res => {
          if (res.data.valid === 1) {
            return true
          }
          return false
        })
        .catch(() => false)
    },
    async checkUsername() {
      this.userExist = null
      if (!this.isValid) return
      this.$v.user.username.$touch()
      if (this.$v.user.username.$error) return
      this.userExist = await this.$http({
        method: 'post',
        url: `/api/oauth/check-username-exist?username=${this.user.username}`,
      })
        .then(res => {
          if (res.data.valid === 1) {
            return true
          }
          return false
        })
        .catch(() => false)
    },
    reset() {
      this.emailExist = null
      this.phoneExist = null
      this.userExist = null
      this.phone = null
      this.user = {
        username: null,
        phoneNumber: null,
        email: null,
        password: null,
        confirmPassword: null,
        lastname: null,
        firstname: null,
        address: null,
        matricule: null,
        profession: null,
        profileImage: '',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    stepOne() {
      this.$v.user.lastname.$touch()
      this.$v.user.firstname.$touch()
      this.$v.user.email.$touch()
      this.$v.user.phoneNumber.$touch()
      this.$v.user.address.$touch()
      this.$v.user.profession.$touch()
      if (this.$v.user.lastname.$error || this.$v.user.firstname.$error || this.$v.user.email.$error || this.$v.user.phoneNumber.$error || this.$v.user.profession.$error || this.$v.user.address.$error || this.emailExist || this.phoneExist || this.userExist) return
      this.$refs.registerWizard.nextTab()
    },
    finalStep() {
      this.$v.user.username.$touch()
      this.$v.user.password.$touch()
      this.$v.user.matricule.$touch()
      this.$v.user.confirmPassword.$touch()
      if (this.$v.user.username.$error || this.$v.user.password.$error || this.$v.user.matricule.$error || this.$v.user.confirmPassword.$error) return
      this.tryToRegister()
    },
    tryToRegister() {
      const roles = [...this.user.roles.map(element => element.value), 'ROLE_USER']
      this.registerUser({
        username: this.user.username,
        phone_number: this.user.phoneNumber,
        email: this.user.email,
        password: this.user.password,
        lastname: this.user.lastname,
        firstname: this.user.firstname,
        address: this.user.address,
        matricule: this.user.matricule,
        profession: this.user.profession,
        profile_image: this.user.profileImage,
        call_back_url: `${window.location.protocol}//${window.location.hostname}/confirm-account`,
        roles,
      })
    },
  },
}
</script>
