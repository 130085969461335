var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('form-wizard',{ref:"registerWizard",attrs:{"step-size":"xs","hide-buttons":true,"color":"#006435","title":"","subtitle":""}},[_c('tab-content',{attrs:{"title":"Informations Personnelles"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nom"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.lastname.$error
                  },attrs:{"formatter":_vm.formatter},model:{value:(_vm.$v.user.lastname.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.lastname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.lastname.$model"}}),(_vm.$v.user.lastname.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.lastname.required)?_c('span',[_vm._v("Nom requis.")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Prénom (s)"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.firstname.$error
                  },attrs:{"formatter":_vm.formatter},model:{value:(_vm.$v.user.firstname.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.firstname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.firstname.$model"}}),(_vm.$v.user.firstname.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.firstname.required)?_c('span',[_vm._v("Prénom (s) requis.")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.email.$error
                  },attrs:{"formatter":_vm.lower},on:{"blur":_vm.checkEmail},model:{value:(_vm.$v.user.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.email.$model"}}),(_vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email requis.")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Entrer un email valide.")]):_vm._e()]):_vm._e(),(_vm.emailExist)?_c('div',[_c('small',{staticClass:"sm text-danger"},[_vm._v("Email déjà utilisé.")])]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Téléphone"}},[_c('vue-phone-number-input',{attrs:{"name":"register-phone","size":"sm","valid-color":"#006435","dark":_vm.dark,"dark-color":"transparent","error-color":"red","translations":_vm.translate,"color":"#006435","fetch-country":true,"only-countries":['BJ'],"border-radius":4,"required":true},on:{"update":function($event){return _vm.update($event)},"phone-number-blur":function($event){return _vm.checkPhone($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}}),(_vm.phoneExist)?_c('div',[_c('small',{staticClass:"sm text-danger"},[_vm._v("Numéro de téléphone déjà utilisé.")])]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.address.$error
                  },attrs:{"formatter":_vm.formatter},model:{value:(_vm.$v.user.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.address, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.address.$model"}}),(_vm.$v.user.address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.address.required)?_c('span',[_vm._v("Adresse requise.")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Profession"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.profession.$error
                  },attrs:{"formatter":_vm.formatter},model:{value:(_vm.$v.user.profession.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.profession, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.profession.$model"}}),(_vm.$v.user.profession.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.profession.required)?_c('span',[_vm._v("Profession requise.")]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary"},on:{"click":_vm.stepOne}},[_vm._v(" Suivant ")])],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Détails du compte"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nom d'utilisateur"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.username.$error
                  },on:{"blur":_vm.checkUsername},model:{value:(_vm.$v.user.username.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.username, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.username.$model"}}),(_vm.$v.user.username.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.username.required)?_c('span',[_vm._v("Nom d'utilisateur requis")]):_vm._e(),(!_vm.$v.user.username.minValue)?_c('span',[_vm._v("Le nom d'utilisateur doit avoir au moins 6 caractères. ")]):_vm._e(),(!_vm.$v.user.username.alphaNum)?_c('span',[_vm._v("Le nom d'utilisateur peut contenir des chiffres et des lettres. ")]):_vm._e()]):_vm._e(),(_vm.userExist)?_c('div',[_c('small',{staticClass:"sm text-danger"},[_vm._v("ELe nom d'utilisateur existe déjà utilisé.")])]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Droits utilisateurs"}},[_c('v-select',{class:{
                    'is-invalid': _vm.$v.user.roles.$error
                  },attrs:{"options":_vm.userRoles,"multiple":""},model:{value:(_vm.$v.user.roles.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.roles, "$model", $$v)},expression:"$v.user.roles.$model"}}),(_vm.$v.user.roles.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.roles.required)?_c('span',[_vm._v("Nom d'utilisateur requis")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Matricule"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.matricule.$error
                  },attrs:{"formatter":_vm.formatter},model:{value:(_vm.$v.user.matricule.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.matricule, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.user.matricule.$model"}}),(_vm.$v.user.matricule.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.matricule.required)?_c('span',[_vm._v("Le numéro matricule est requis")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Mot de passe"}},[_c('b-input-group',{staticClass:"input-group-merge",class:{ 'is-invalid': _vm.$v.user.password.$error }},[_c('b-form-input',{class:{
                      'is-invalid': _vm.$v.user.password.$error
                    },attrs:{"type":_vm.passwordFieldType},model:{value:(_vm.$v.user.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.password, "$model", $$v)},expression:"$v.user.password.$model"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}}),_c('feather-icon',{staticClass:"cursor-pointer ml-1",attrs:{"icon":"KeyIcon"},on:{"click":_vm.generatePassword}}),_c('feather-icon',{staticClass:"cursor-pointer ml-1",attrs:{"icon":"CopyIcon"},on:{"click":_vm.copyPassword}})],1),(_vm.$v.user.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.password.required)?_c('span',[_vm._v("Mot de passe requis")]):_vm._e(),(!_vm.$v.user.password.password)?_c('span',[_vm._v("Le mot de passe doit contenir au moins 8 caractères y compris une majuscule et une lettre minuscule, un nombre et un caractère spécial.")]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Confirmation"}},[_c('b-form-input',{class:{
                    'is-invalid': _vm.$v.user.confirmPassword.$error
                  },attrs:{"type":"password"},model:{value:(_vm.$v.user.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.confirmPassword, "$model", $$v)},expression:"$v.user.confirmPassword.$model"}}),(_vm.$v.user.confirmPassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.confirmPassword.required)?_c('span',[_vm._v("Mot de passe requis")]):_vm._e(),(!_vm.$v.user.confirmPassword.sameAs)?_c('span',[_vm._v("Mots de passe non identiques")]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{staticClass:"float-left",attrs:{"variant":"outline-primary","disabled":_vm.process},on:{"click":function($event){return _vm.$refs.registerWizard.prevTab()}}},[_vm._v(" Précédent ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary","disabled":_vm.process},on:{"click":_vm.finalStep}},[(_vm.process)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.process),expression:"!process"}]},[_vm._v("Enrégister")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }